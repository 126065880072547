import React from "react";
import instagram from "../assets/insta.png";
import facebook from "../assets/face.png";
import youtube from "../assets/youtube.png";
import { Link } from "react-router-dom";

import linkedin from "../assets/linkedinn.png";
import twitter from "../assets/twitter.png";
import toparrow from "../assets/upper-arrow.png";

export default function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="frame"></div>
        <div className="follow-con">
          <div className="follow">
            <p className="follow-text">BİZİ TAKİP EDİN</p>
            <div className="follow-logos">
              <Link target="_blank" to="https://www.instagram.com/camgrup/">
                <img className="logos" src={instagram} alt="" />
              </Link>
              <Link
                target="_blank"
                to="https://www.facebook.com/camdisticaret/?locale=tr_TR"
              >
                <img className="logos" src={facebook} alt="" />
              </Link>
              <Link
                target="_blank"
                to="https://www.linkedin.com/in/%C3%A7am-grup-12075621b/"
              >
                <img className="logos" src={linkedin} alt="" />
              </Link>
              <Link target="_blank" to="https://www.youtube.com/@camdisticaret">
                <img className="logos" src={youtube} alt="" />
              </Link>
              <Link target="_blank" to="https://twitter.com/camdisticaret">
                <img className="logos" src={twitter} alt="" />
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>Çam Grup © 2023 - Tüm Hakları Saklıdır. </p>
          <a href="#top">
            <img src={toparrow} alt="" />
          </a>
        </div>
      </footer>
    </div>
  );
}
