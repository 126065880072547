import React, { useState } from "react";
import Footer from "../components/Footer";
import peysaj from "../assets/images/peysaj/peyzaj.png";
import prestij from "../assets/images/peysaj/prestij.png";
import click from "../assets/images/peysaj/click.png";
import kristal from "../assets/images/peysaj/kristal.png";
import Header from "../components/Header";
import disTicaret from "../assets/images/sektor/disTicaret.jpg";
import camGrup from "../assets/images/sektor/camGrup.png";
import havacilik from "../assets/images/sektor/havacilik.jpg";
import vizyon from "../assets/images/sektor/vizyon.png";
import vafa from "../assets/images/sektor/vafa.png";
import pic from "../assets/images/sektor/pic.png";
import fuar from "../assets/images/sektor/fuar.jpg";
import infoo from "../assets/images/sektor/infoo.png";
import medya from "../assets/images/sektor/medya.jpg";
import altin from "../assets/images/sektor/altin.png";
import up from "../assets/images/sektor/up.png";
import imaj from "../assets/images/sektor/imaj.png";
import turizm from "../assets/images/sektor/turizm.jpg";
import burj from "../assets/images/sektor/burj.png";
import tarım from "../assets/images/sektor/tarım.jpg";
import drone from "../assets/images/sektor/drone.png";
import store from "../assets/images/sektor/store.png";
import mall from "../assets/images/sektor/mall.png";
import insaat from "../assets/images/sektor/insaat.png";
import garanti from "../assets/images/sektor/garanti.png";
import kristall from "../assets/images/sektor/kristall.png";
import güvenlik from "../assets/images/sektor/güvenlik.jpg";
import pima from "../assets/images/sektor/pima.png";
import bilisim from "../assets/images/sektor/bilisim.jpg";
import arac from "../assets/images/sektor/arac.jpg";
import star from "../assets/images/sektor/star.png";
export default function Peysaj() {
  const [activeIndex, setActiveIndex] = useState(0);
  const menuData = [
    "Peyzaj",
    "Dış Ticaret",
    "Sivil Havacılık",
    "Fuar Organizasyon",
    "Medya",
    "Turizm",
    "Tarım",
    "İnşaat",
    "Güvenlik",
    "Bilişim",
    "Araç Kiralama",
  ];
  const data = [
    {
      id: 1,
      title: "Peyzaj",
      img: peysaj,
      companies: [
        {
          name: "PRESTİJ PARK DIŞ TİCARET",
          logo: prestij,
          descrition:
            "Prestij Park olarak kurulduğumuz günden bu yana doğanın <br /> hiçbir zaman bozulmaması gerektiğini düşünerek projelerimizde <br /> doğa-insan ilişkisini ön planda tuttuk. Bu doğrultuda özel arazi <br /> ve alanlardan kamu alanlarına dek park ve bahçe alanı <br /> projelerimiz ile daha yeşil alanlar geliştirmeye devam ediyoruz. <br /> <br /> Ana hedefimiz her zaman ; akılcı, uygulanabilir, sürdürülebilir, <br /> çevresiyle uyumlu, tasarımsal yaklaşımıyla fark yaratacak projeler <br /> üretmek ve hayata geçirmektir. <br /> Bu ilkeler ışığında , kişisel yada kurumsal bizi tercih edenlerin ,<br /> her ölçekte ki talebine aynı heyecanla yaklaşmaya, en iyi <br /> iletişimle, en iyi sonucu almak için çalışmaya devam etmekteyiz.",
          website: "www.prestijpark.net",
        },
        {
          name: "KRİSTAL MERKEZİ YAPI İŞLETME YÖNETİM VE İNŞAAT A.Ş.",
          logo: kristal,
          descrition:
            "Doğadan esinlenerek, tabiatın gücünü kent hayatına uyarlayan <br /> bir anlayışla 2002 yılında kurulan Kristal Peyzaj, yaşam alanlarının <br /> ihtiyaçlarını karşılamak üzere sunduğu hizmet kalitesini her <br /> geçen gün geliştiriyor. <br /> <br /> Kurulduğumuz günden bugüne alanında uzman ve tecrübeli teknik kadromuz, doğayla uyumlu estetik anlayışımız, güçlü ve modern makine ekipmanlarımız ile başarılı projelere imza <br /> atmaya devam ediyoruz. Modern ve kullanışlı kent mobilyaları <br /> üreterek, devlet kurumlarından kent parklarına kadar birçok <br /> alanda insan sağlığına zarar vermeyen ve estetik ürünler geliştiriyoruz.",
          website: "www.kristalpeyzaj.com.tr",
        },
      ],
    },
    {
      id: 2,
      title: "Dış Ticaret",
      img: disTicaret,
      companies: [
        {
          name: "ÇAM GRUP DIŞ TİCARET",
          logo: camGrup,
          descrition:
            "Canavar Yasin Prestij Park olarak kurulduğumuz günden bu yana doğanın <br /> hiçbir zaman bozulmaması gerektiğini düşünerek projelerimizde <br /> doğa-insan ilişkisini ön planda tuttuk. Bu doğrultuda özel arazi <br /> ve alanlardan kamu alanlarına dek park ve bahçe alanı <br /> projelerimiz ile daha yeşil alanlar geliştirmeye devam ediyoruz. <br /> <br /> Ana hedefimiz her zaman ; akılcı, uygulanabilir, sürdürülebilir, <br /> çevresiyle uyumlu, tasarımsal yaklaşımıyla fark yaratacak projeler <br /> üretmek ve hayata geçirmektir. <br /> Bu ilkeler ışığında , kişisel yada kurumsal bizi tercih edenlerin ,<br /> her ölçekte ki talebine aynı heyecanla yaklaşmaya, en iyi <br /> iletişimle, en iyi sonucu almak için çalışmaya devam etmekteyiz.",
          website: "www.camgrup.org/",
        },
        {
          name: "Prestİj Park Dış Tİcaret",
          logo: prestij,
          descrition:
            "Prestij Park olarak kurulduğumuz günden bu yana doğanın <br /> hiçbir zaman bozulmaması gerektiğini düşünerek projelerimizde <br /> doğa-insan ilişkisini ön planda tuttuk. Bu doğrultuda özel arazi <br /> ve alanlardan kamu alanlarına dek park ve bahçe alanı <br /> projelerimiz ile daha yeşil alanlar geliştirmeye devam ediyoruz. <br /> <br /> Ana hedefimiz her zaman ; akılcı, uygulanabilir, sürdürülebilir, <br /> çevresiyle uyumlu, tasarımsal yaklaşımıyla fark yaratacak projeler <br /> üretmek ve hayata geçirmektir. <br /> Bu ilkeler ışığında , kişisel yada kurumsal bizi tercih edenlerin ,<br /> her ölçekte ki talebine aynı heyecanla yaklaşmaya, en iyi <br /> iletişimle, en iyi sonucu almak için çalışmaya devam etmekteyiz.",
          website: "www.prestijpark.net",
        },
      ],
    },
    {
      id: 3,
      title: "Sivil Havacılık",
      img: havacilik,
      companies: [
        {
          name: "VİZYON HAVACILIK",
          logo: vizyon,
          descrition:
            "Vizyon Havacılık 2014 yılında kurulmuş olup, havacılık sektöründe hizmet vermeye başlamıştır.<br /> Güvenlik ve emniyet sistemlerini en önemli unsur olarak temel alan ve her kademede eksiksiz kullanmaya özen ve itina gösteren Vizyon Havacılık, sürekli gelişen havacılık standartlarına hizmet kalitesinden ödün vermeden uyum sağlamayı temel ilkelerinden biri olarak kabul etmiştir.  <br /> Vizyon Havacılık, kabiliyetlerini ve kapasitesini artırarak, yurt içi ve yurt dışında da bireysel ve kurumsal müşterilere hizmet verir hale gelmeyi planlamakta ve müşteri memnuniyeti yaratan bir havacılık şirketi olma kararlılığı ile ilerlemektedir. Vizyon Havacılık, uzun süren deneyim, birikim ve ileriye dönük atılımların bir araya getirilmesi hedefi ile kurulmuş havacılık sektöründe istikrarla gelişen kurumsal bir şirkettir.",
          website: "www.vizyonhavacilik.com/",
        },
        {
          name: "VAFA AERO",
          logo: vafa,
          descrition:
            "Prestij Park olarak kurulduğumuz günden bu yana doğanın hiçbir zaman bozulmaması gerektiğini düşünerek projelerimizde doğa-insan ilişkisini ön planda tuttuk. Bu doğrultuda özel arazi ve alanlardan kamu alanlarına dek park ve bahçe alanı projelerimiz ile daha yeşil alanlar geliştirmeye devam ediyoruz.Ana hedefimiz her zaman ; akılcı, uygulanabilir, sürdürülebilir, çevresiyle uyumlu, tasarımsal yaklaşımıyla fark yaratacak projeler üretmek ve hayata geçirmektir.Bu ilkeler ışığında , kişisel yada kurumsal bizi tercih edenlerin , her ölçekte ki talebine aynı heyecanla yaklaşmaya, en iyi iletişimle, en iyi sonucu almak için çalışmaya devam etmekteyiz.<br/>",
          website: "https://vafaaero.com/",
        },
        {
          name: "PICCLUB",
          logo: pic,
          descrition:
            "PICCLUB Vizyon Havacılık’ın tescilli bir alt markasıdır. Türkiye’nin ilgili tüm yetkilere sahip en büyük uçuş okullarından birisi olan Vizyon Havacılık Eğitim Akademisi, Pilot in Command (PIC) uçuşlarını PICCLUB markası altında gerçekleştirmektedir. Görsel tasarımı ve tüm kurumsal kimlik çalışmaları ile hem göze hem kalbe hitap eden PICCLUB, PICCLUBBER’ların kilometre taşlarında havalı bir iz bırakmayı hedeflemektdir. Tüm PIC uçuşlarımızda en az CPL lisansına sahip gözetmen pilotlarımız eşliğinde PPL(Private Pilot License) tekrarı ve IR( Instrument Rating) hazırlığı da hedeflenmektedir.PIC modülü eğitim sürecinin en keyifli kısımlarından birisidir. PICCLUB, PIC uçuş deneyimini en üst seviyede, kurumsal bir duruşla, yepyeni uçaklar ile gerçekleştirmek için hayata geçmiştir.<br/>",
          website: "http://picclub.com.tr/",
        },
      ],
    },
    {
      id: 4,
      title: "Fuar Organizasyon",
      img: fuar,
      companies: [
        {
          name: "İnfo Uluslararası Fuar Tanıtım A.Ş",
          logo: infoo,
          descrition:
            "INFO FUARCILIK, 1994 yılında Anadolu’da tarım fuarcılığının temelini atarak sektöre girmiştir. Hala devam eden ve Türkiye’ nin en başarılı tarım fuarı olan AGROTEC, sektörün en büyük markasıdır.Kuruluşundan bu yana ulusal ve uluslararası kongre, açılış ve çeşitli organizasyonlara imza atan şirketimiz, hedeflerini büyüterek 2005 yılından itibaren fuar takvimini genişletmiş ve başarılı genişleyen kadrosu ile yine Türkiye’nin en büyük ve en başarılı Belediye Ekipmanları, Çevre Teknolojileri ve Geri Dönüşüm, Gıda ve Gıda Teknolojileri, Güvenlik ve Güvenlik Teknolojileri, Kent Mobilyaları ve Peyzaj, Otomobil ve Yan Sanayi, Anne ve Çocuk İhtiyaçları, Makine ve Makine Teknolojileri, Su ve Su Teknolojileri, Banyo Mutfak ve Aksesuarları, Ambalaj ve Paketleme, Peyzaj ve Bahçe Mobilyaları Fuarlarını organize etmiştir.",
          website: "infofair.com.tr",
        },
      ],
    },
    {
      id: 5,
      title: "Medya",
      img: medya,
      companies: [
        {
          name: "ALTIN YAPIM",
          logo: altin,
          descrition:
            "Altın Yapım, 2006 yılında Çam Grup şirketine bağlı olarak kuruldu. Merkez ofisi Ankara, Beysukent’te bulunan Altın Yapım,dijital dünyaya açılan kapınız olmaya hazır! <br/>Altın Yapım olarak alanında profesyonel Sosyal Medya, Dijital Pazarlama ve Web Tasarım ekiplerimiz ile markanızın dijital dönüşümünü sağlamak adına oluşturacağınız istihdam yükünden sizleri rahatlatmayı hedefliyoruz. <br/>Sizler için oluşturacağımız tüm bu çalışmalar ile markanıza yeni bir çehre oluşturarak bilinilirliğinizi artırmayı ve yeni kitleleri sizlere ulaştırmayı hedefliyoruz. ",
          website: "https://www.altinyapim.com/",
        },
        {
          name: "UP MEDYA",
          logo: up,
          descrition:
            "Up Medya olarak, Matbaa, Web Tasarım ve Promosyon departmanları ile başta kurumsal firmalar olmak üzere tüm müşterilerine en kaliteli hizmeti vermeyi hedeflemektedir. Müşteri memnuniyetini ön planda tutarak kaliteli ve ekonomik çözümleri zamanında müşterilerimize sunmaktayız",
          website: "http://www.upmedya.com.tr/",
        },
        {
          name: "IMAJ DESING & NETWORK",
          logo: imaj,
          descrition:
            "Bilişim sektöründe 2001 yılından bugüne birçok kurumsal firmaya hizmet ve danışmanlık veren Designota, her geçen yıl alanında uzman ve profesyonel ekibini büyüterek çalışmalarına hız kesmeden devam etmektedir.<br/>Müşteri memnuniyetini en üst düzeyde tutan ve en iyi hizmeti sunmaya gayret göstererek, kurulduğu günden bu güne, İmaj Dizayn ile çalışmayı seçmiş her bir müşteri, memnuniyetle çalışmaya devam etmiştir.<br/>İmaj Dizayn’da üretilen kreatif, inovatif ve kalıcı çözümlerle, işletmelerin kurumsal değişimlerini en hızlı ver başarılı şekilde yönetmelerine yardımcı oluyor. Bunu başarırken, maliyet ve süre konusunda son derece titizlik gösteriliyor.",
          website: "http://imajdizayn.com.tr/",
        },
      ],
    },
    {
      id: 6,
      title: "Turizm",
      img: turizm,
      companies: [
        {
          name: "BURJ TURİZM",
          logo: burj,
          descrition:
            "2004 yılında kurulmuş olan Burj Turizm, uzman kadrosu ve kaliteyi hedefleyen ilkeleriyle misafirlerimizin güvenini kazanarak tercih sebebi olmaya devam etmektedir.<br/>Kuruluşundan itibaren hızlı şekilde büyüyerek yurt içi organize seyahat sektöründe ilkleri gerçekleştirme misyonuna sahip bir tur operatörü olan Burj Turizm, vizyoner yapısı ile de birçok ilke imza attı.<br/>Turizm Sektöründe yaklaşık 11 yıllık deneyimimiz ile her zaman müşteri memnuniyeti, en kaliteli satış ve hizmeti sunmak ilkelerimizin başında yer almaktadır.",
          website: "/http://burjturizm.com/",
        },
      ],
    },
    {
      id: 7,
      title: "Tarım",
      img: tarım,
      companies: [
        {
          name: "SKY AGRO DRONE AKILLI TARIM",
          logo: drone,
          descrition:
            "Türkiye genelinde insansız hava araçlarımız ile tarımsal alanlarda zirai ilaçlama, tarımsal toprak analizi, bitki sağlığı analizi hizmetleri vermekteyiz.<br/><br/> Ayrıca maden sahalarında kullandığımız insansız hava araçlarımızla stok hacimlerini belirleme, kazı bölgelerini belirleme, maden alanlarının risk analizini yapma hizmetlerimizin yanı sıra enerji sektöründe solar enerji panellerinin denetlenmesi ve temizlenmesi, enerji boru hatlarında inceleme, rüzgar panellerinin denetlenmesi ve temizlenmesi, petrol borularında sızıntı takibi gibi birçok alanda deneyimli personellerimizle yanınızdayız.",
          website: "www.skyagrodrone.com/",
        },
        {
          name: "STORE AGRO TARIMSAL MAKİNE üRÜNLERİ ",
          logo: store,
          descrition:
            "Store Agro; Çam Grup bünyesinde 2022 yılında kurulmuş olup traktör, saban, biçerdöver vb. tarımsal makine ürünlerinin ihracatının sağlanması adına faaliyetlerini sürdürmektedir.",
          website: "http://www.upmedya.com.tr/",
        },
        {
          name: "MALL OF ARGO TARIMSAL ÜRÜNLER ",
          logo: mall,
          descrition:
            "Mall Of Agro; Çam Grup bünyesinde 2022 yılında kurulmuş olup, fide, gübre, tohum vb. tarımsal ürünlerinin ihracatının sağlanması adına faaliyetlerini sürdürmektedir.",
          website: "http://mallofagro.com/",
        },
      ],
    },
    {
      id: 8,
      title: "İnşaat",
      img: insaat,
      companies: [
        {
          name: "GARANTİ MERKEZİ YAPI",
          logo: garanti,
          descrition:
            "Garanti Merkezi Yapı; büyüyen ve kalkınmanın taşlarından biri olan inşaat sektörünün üstlendiği yaşamsal işlevleri, en ince ayrıntılardan başlayarak daima en iyiyi hedeflemektedir.<br/>Çağdaş dünyanın hızla değişen beklentilerine en doğru çözümlerle yanıt vermekte olup; tecrübeli ekibi, kurumsal ve güçlü sermaye yapısı ile ayrıcalıklı inşaat projelerine imza atmaktadır.<br/>Gerçekleştirdiği her projeye çağdaş bakış açısıyla yaklaşır, tüm süreçlerde projeye emek verenler projenin özüyle bütünleştiği için mükemmel bir işbirliği ile çalışırlar.<br/>En üst düzeyde müşteri memnuniyetini hedefleyerek hizmet ulaştırdığımız her yeni kurum ve her yeni birey bu değerler zincirinin vazgeçilmez bir parçası olarak aramıza katılıyor.<br/>Garanti Merkezi Yapı değerlerinden ödün vermeyerek dünya standartlarına en başarılı projeler üretmeye ve sunmaya devam edecektir.",
          website: "http://garantimerkeziyapi.com/",
        },
        {
          name: "Krİstal Merkezİ Yapı İşletme Yönetİm ve İnşaat A.Ş.",
          logo: kristall,
          descrition:
            "Doğadan esinlenerek, tabiatın gücünü kent hayatına uyarlayan bir anlayışla 2002 yılında kurulan Kristal Peyzaj, yaşam alanlarının ihtiyaçlarını karşılamak üzere sunduğu hizmet kalitesini her geçen gün geliştiriyor. <br/>Kurulduğumuz günden bugüne alanında uzman ve tecrübeli teknik kadromuz, doğayla uyumlu estetik anlayışımız, güçlü ve modern makine ekipmanlarımız ile başarılı projelere imza atmaya devam ediyoruz. Modern ve kullanışlı kent mobilyaları üreterek, devlet kurumlarından kent parklarına kadar birçok alanda insan sağlığına zarar vermeyen ve estetik ürünler geliştiriyoruz.",
          website: "www.prestijpark.net",
        },
      ],
    },
    {
      id: 9,
      title: "Güvenlik",
      img: güvenlik,
      companies: [
        {
          name: "PİMA GÜVENLİK SİSTEMLERİ",
          logo: pima,
          descrition:
            "PİMA Elektronik Güvenlik Sistemleri konusunda hizmet vermek amacıyla 2015 yılında Ankara’da kurulmuştur. Alarm izleme merkezi günün 24 saati uzman operatörleri ile alarm sistemlerini teknolojinin en yeni sistemi ile izlemektedir.<br/>Abonelerin gelen alarm ihbarları anında değerlendirilerek emniyet, itfaiye v.b. kurumlara bilgisi verilerek müdahale edilmektedir.<br/>Pima Güvenlik, kişi ve kurumlara yönelik özel elektronik güvenlik çözümleri, alarm sistemleri ve Alarm İzleme Merkezi ile elektronik güvenlik alanında Türkiye genelinde hizmet sunmaktadır.<br/>Dünyadaki teknolojik gelişmeleri takip eden Pima Güvenlik A.Ş., uzman mühendis kadrosu ile elektronik güvenlik alanında hizmet vermektedir. Şirketimiz, projeye özel elektronik güvenlik sistemi tasarımı yapmakta ve etkin güvenlik çözümleri sunmaktadır.",
          website: "https://pima.com.tr/",
        },
      ],
    },
    {
      id: 10,
      title: "Bilişim",
      img: bilisim,
      companies: [
        {
          name: "IMAJ DESING NETWORK",
          logo: imaj,
          descrition:
            "Bilişim sektöründe 2001 yılından bugüne birçok kurumsal firmaya hizmet ve danışmanlık veren Designota, her geçen yıl alanında uzman ve profesyonel ekibini büyüterek çalışmalarına hız kesmeden devam etmektedir.<br/>Müşteri memnuniyetini en üst düzeyde tutan ve en iyi hizmeti sunmaya gayret göstererek, kurulduğu günden bu güne, İmaj Dizayn ile çalışmayı seçmiş her bir müşteri, memnuniyetle çalışmaya devam etmiştir.<br/>İmaj Dizayn’da üretilen kreatif, inovatif ve kalıcı çözümlerle, işletmelerin kurumsal değişimlerini en hızlı ver başarılı şekilde yönetmelerine yardımcı oluyor. Bunu başarırken, maliyet ve süre konusunda son derece titizlik gösteriliyor.",
          website: "http://imajdizayn.com.tr/",
        },
        {
          name: "UP MEDYA",
          logo: up,
          descrition:
            "Up Medya olarak, Matbaa, Web Tasarım ve Promosyon departmanları ile başta kurumsal firmalar olmak üzere tüm müşterilerine en kaliteli hizmeti vermeyi hedeflemektedir. Müşteri memnuniyetini ön planda tutarak kaliteli ve ekonomik çözümleri zamanında müşterilerimize sunmaktayız",
          website: "www.upmedya.com.tr/",
        },
      ],
    },
    {
      id: 11,
      title: "Araç Kiralama",
      img: arac,
      companies: [
        {
          name: "STAR ARAÇ KİRALAMA",
          logo: star,
          descrition:
            "Star Araç Kiralama  bünyesinde bulunan tüm araçlarımız isteğiniz doğrultusunda adresinize kadar getirilmektedir. Ek olarak Havalaanı transfer hizmetide sunan firmamız sizlere araç kiralama sektöründe kaliteli hizmet vermektedir. Araç kiralama firmaları arasında en güvenilir,rahat,konforlu ve profesyonel hizmet sunmayı amaçlayan Star araç kiralama, havalimanında,tren garında,otobüs terminallerinde konforlu araba kiralamada ön plandadır.<br/>Çalışanlarımızın esnek,düşünen,rekabetçi,yenilikçi,kalite yönetimi felsefesini yaşam biçimi halin getirmiş,sinerji oluşturan,değişime hızla ayak uydurabilen bireylerden oluşması da diğer araç kiralama şirketlerinden ayrıca farklarımızdandır. Sitemiz ile üç adımda, hızlı ve güvenli rezervasyon yaparken en uygun fiyatlarla araç temin etmek esas amacımızdır. ",
          website: "www.stararackiralama.com/",
        },
      ],
    },
  ];
  return (
    <>
      {console.log(data[activeIndex])}
      <div className="con-peysajj">
        <Header />
        <div className="com-web-container">
          <div className="com-web-ustbilgi">
            <div className="com-web-sektor">SEKTÖRLERİMİZ</div>
            <div className="com-web-sirketler">ŞİRKETLERİMİZ</div>
          </div>

          <div className="com-web-row">
            <div className="com-web-left">
              <div className="com-web-panel">
                <div className="com-web-list">
                  {menuData.map((item, index) => (
                    <div
                      className={`flex-wrap com-web-distic ${
                        index === activeIndex && "active"
                      }`}
                      onClick={() => setActiveIndex(index)}
                    >
                      &nbsp;&nbsp;{item}
                    </div>
                  ))}
                  {/* <div className="flex-wrap com-web-peyzaj active ">
                    &nbsp;&nbsp;Peyzaj
                  </div>
                  <div className="flex-wrap com-web-distic">
                    &nbsp;&nbsp;Dış Ticaret
                  </div> */}

                  <br />
                </div>
              </div>

              <div className="com-web-foto">
                <div className="com-web-header">{data[activeIndex].title}</div>
                <div className="com-web-line"></div>
                <div className="com-web-photo">
                  <img src={data[activeIndex].img} />
                  {console.log(data[activeIndex].img)}
                </div>
              </div>
            </div>
            {console.log(data[activeIndex].companies.length)}
            {data[activeIndex].companies.length == 1 ? (
              <div className="componiess">
                <div className="com-web-right">
                  <div className="com-web-ust">
                    <div className="com-web-head">
                      {data[activeIndex].companies[0].name}
                    </div>
                    <div className="com-web-smline">
                      <div className="com-web-lines"></div>
                    </div>
                    <div className="com-web-blog">
                      <div className="com-web-ustimg">
                        <img src={data[activeIndex].companies[0].logo} />
                      </div>
                      <div className="com-web-aracizgi"></div>
                      <div
                        className="com-web-altbilgi"
                        dangerouslySetInnerHTML={{
                          __html: data[activeIndex].companies[0].descrition,
                        }}
                      />
                      <div className="com-web-link">
                        <div className="com-web-click">
                          <img src={click} />
                        </div>
                        <div className="com-web-park">
                          <a
                            href={`http://${data[activeIndex].companies[0].website}`}
                            target="_blank"
                          >
                            {data[activeIndex].companies[0].website}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {data[activeIndex].companies.length == 2 ? (
              <div className="componiess">
                <div className="com-web-right">
                  <div className="com-web-ust">
                    <div className="com-web-head">
                      {data[activeIndex].companies[0].name}
                    </div>
                    <div className="com-web-smline">
                      <div className="com-web-lines"></div>
                    </div>
                    <div className="com-web-blog">
                      <div className="com-web-ustimg">
                        <img src={data[activeIndex].companies[0].logo} />
                      </div>
                      <div className="com-web-aracizgi"></div>
                      <div
                        className="com-web-altbilgi"
                        dangerouslySetInnerHTML={{
                          __html: data[activeIndex].companies[0].descrition,
                        }}
                      />
                      <div className="com-web-link">
                        <div className="com-web-click">
                          <img src={click} />
                        </div>
                        <div className="com-web-park">
                          <a
                            href={`http://${data[activeIndex].companies[0].website}`}
                            target="_blank"
                          >
                            {data[activeIndex].companies[0].website}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="com-web-alt">
                  <div className="com-web-althead">
                    {data[activeIndex].companies[1].name}
                  </div>
                  <div className="com-web-altsmline">
                    <div className="com-web-altlines"></div>
                  </div>
                  <div className="com-web-blog">
                    <div className="com-web-ustimg">
                      <img src={data[activeIndex].companies[1].logo} />
                    </div>
                    <div className="com-web-aracizgi"></div>
                    <div
                      className="com-web-altbilgi"
                      dangerouslySetInnerHTML={{
                        __html: data[activeIndex].companies[1].descrition,
                      }}
                    />
                    <div className="com-web-link">
                      <div className="com-web-click">
                        <img src={click} />
                      </div>
                      <div className="com-web-park">
                        <a
                          href={`http://${data[activeIndex].companies[1].website}`}
                          target="_blank"
                        >
                          {data[activeIndex].companies[1].website}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {data[activeIndex].companies.length == 3 ? (
              <div className="componiess">
                <div className="com-web-right">
                  <div className="com-web-ust">
                    <div className="com-web-head">
                      {data[activeIndex].companies[0].name}
                    </div>
                    <div className="com-web-smline">
                      <div className="com-web-lines"></div>
                    </div>
                    <div className="com-web-blog">
                      <div className="com-web-ustimg">
                        <img src={data[activeIndex].companies[0].logo} />
                      </div>
                      <div className="com-web-aracizgi"></div>
                      <div
                        className="com-web-altbilgi"
                        dangerouslySetInnerHTML={{
                          __html: data[activeIndex].companies[0].descrition,
                        }}
                      />
                      <div className="com-web-link">
                        <div className="com-web-click">
                          <img src={click} />
                        </div>
                        <div className="com-web-park">
                          <a
                            href={`http://${data[activeIndex].companies[0].website}`}
                            target="_blank"
                          >
                            {data[activeIndex].companies[0].website}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="com-web-alt">
                  <div className="com-web-althead">
                    {data[activeIndex].companies[1].name}
                  </div>
                  <div className="com-web-altsmline">
                    <div className="com-web-altlines"></div>
                  </div>
                  <div className="com-web-blog">
                    <div className="com-web-ustimg">
                      <img src={data[activeIndex].companies[1].logo} />
                    </div>
                    <div className="com-web-aracizgi"></div>
                    <div
                      className="com-web-altbilgi"
                      dangerouslySetInnerHTML={{
                        __html: data[activeIndex].companies[1].descrition,
                      }}
                    />
                    <div className="com-web-link">
                      <div className="com-web-click">
                        <img src={click} />
                      </div>
                      <div className="com-web-park">
                        <a
                          href={`http://${data[activeIndex].companies[1].website}`}
                          target="_blank"
                        >
                          {data[activeIndex].companies[1].website}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="com-web-alt">
                  <div className="com-web-althead">
                    {data[activeIndex].companies[2].name}
                  </div>
                  <div className="com-web-altsmline">
                    <div className="com-web-altlines"></div>
                  </div>
                  <div className="com-web-blog">
                    <div className="com-web-ustimg">
                      <img src={data[activeIndex].companies[2].logo} />
                    </div>
                    <div className="com-web-aracizgi"></div>
                    <div
                      className="com-web-altbilgi"
                      dangerouslySetInnerHTML={{
                        __html: data[activeIndex].companies[2].descrition,
                      }}
                    />
                    <div className="com-web-link">
                      <div className="com-web-click">
                        <img src={click} />
                      </div>
                      <div className="com-web-park">
                        <a
                          href={`http://${data[activeIndex].companies[2].website}`}
                          target="_blank"
                        >
                          {data[activeIndex].companies[2].website}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
