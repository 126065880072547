import "../App.css";

import hakkimizda from "../assets/hakkımızda.png";
import line from "../assets/line.png";
import mail from "../assets/email.png";
import location from "../assets/location.png";
import phone from "../assets/phone.png";
import lineD from "../assets/line-d.png";
import radio from "../assets/radio.png";
import dat from "../assets/dat.png";
import prestij from "../assets/prestij.png";
import sirket from "../assets/sirketlerimiz.jpg";
import sirket_line from "../assets/sirket-line.png";
import sivil from "../assets/sivilS.jpg";
import peysaj from "../assets/peysaj.jpg";
import fuar from "../assets/fuar.png";
import radyo from "../assets/radyo.jpg";
import ticaret from "../assets/ticaret.jpg";
import white from "../assets/white.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import bsb from "../assets/bsb.png";
import camgroup from "../assets/cam-grup.png";
import kristal from "../assets/kristal.png";
import picclub from "../assets/picclub.png";
import vafa from "../assets/vafa.png";
import vizyon from "../assets/vizyon.png";
import upmedya from "../assets/upmedya.png";
import imaj from "../assets/imaj.png";
import info from "../assets/info.png";
import altin from "../assets/altınyapım.png";
import camturizm from "../assets/camturizm.png";

import garanti from "../assets/garanti.png";
import pima from "../assets/pima.png";
import pierre from "../assets/pierrem.png";

import palmedia from "../assets/palmedia.png";
import palstation from "../assets/palstation.png";
import palnostalji from "../assets/noltalji.png";
import palfm from "../assets/palfm.png";
import palfmR from "../assets/palfmR.png";
import indexAnime from "../assets/indexAnime/indexAnime.gif";
import indexAnime2 from "../assets/indexAnime/indexAnime2.gif";
import indexAnime3 from "../assets/indexAnime/indexAnime3.gif";
import { Link } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Header name="top" />

      <div className="container">
        <div className="hakkımızda">
          <div className="hk-img">
            <a>
              <img className="hk-img-png" src={hakkimizda} alt="" />
            </a>
          </div>
          <div className="hakkımızda-texts">
            <div className="title">Çam Grup ile Hep Daha İleriye!</div>
            <div className="line-text">
              <img className="line" src={line} alt="" />
              <p>İŞ BİRLİKLERİMİZLE BÜYÜYORUZ</p>
            </div>
            <div className="explanation">
              <p>
                Çam Grup, 30 yıldır istikrarlı bir şekilde büyüyerek; Türkiye
                ekonomisine önemli katkılarda bulunmuş ve güvenilirliği ile
                birçok sektöre değer kazandırmıştır. Bu doğrultuda ticaretin ve
                fuar turizminin gelişmesine katkıda bulunmak amacıyla çıktığı
                yolda farklı birçok sektöre yatırımlarda bulunmuştur.
              </p>
              <p className="exp-text">
                Grup şirket yönetimi hala; dış ticaret, fuarcılık, peyzaj,
                inşaat, turizm, medya, araç kiralama, havacılık, güvenlik,
                bilişim ve teknoloji gibi pek çok alanda faaliyette bulunmaya ve
                ilk günkü heyecan ile yatırımları sürdürmeye devam etmektedir.
                <br />
                <br />
                Çam Grup, 30 yılı aşkın süredir birçok alanda uluslararası
                standartlarda gerçekleştirdiği projelerle, sektöründe ülkesinin
                atılımcı kuruluşlarından birisi haline gelmiştir.
                <br />
                <br />
                Geride bıraktığı hizmet süresinde, her zaman dinamizmini
                deneyimi ile kaynaştıran, yatırımlarını planlı olarak yapan,
                dünyadaki tüm teknolojik gelişmeleri grup bünyesinde uygulamayı
                ilke edinen Çam Grup, Türkiye’de sürdürdüğü itibar ve başarısını
                yurtdışına açılarak pekiştirmektedir.
              </p>
            </div>
          </div>
        </div>
        <Link to="/About">
          <div className="devam">Devamı</div>
        </Link>

        <div className="history">
          <div className="history-line">
            <div className="dat">
              <img
                className="dat-image"
                width={10}
                height={10}
                src={dat}
                alt=""
              />
              <p className="com-text">Tarihçe</p>
            </div>
            <img
              className="com-line-history-1"
              height={400}
              width={3}
              src={lineD}
              alt=""
            />
          </div>
          <div class="history-frame">
            <div className="history-frame-context">
              <div className="history-context-left">
                <p className="history-title">1994</p>
                <img width={400} src={white} alt="" />
                <p className="history-text">
                  INFO FUARCILIK, 1994 yılında Anadolu’da tarım fuarcılığının
                  temelini atarak sektöre girmiştir.
                </p>
              </div>
              <div className="history-context-right">
                <p className="history-title">1996</p>
                <img width={400} src={white} alt="" />
                <p className="history-text">
                  Pal DOĞA, 1996 Yılında Yayın Hayatına başlayan 100.9
                  frekansında yayın hayatına başlamıştır.
                </p>
              </div>
            </div>
            <div className="history-frame-context">
              <div className="history-context-left">
                <p className="history-title">2001</p>
                <img width={400} src={white} alt="" />
                <p className="history-text">
                  İmaj Dizayn, her geçen yıl alanında uzman ve profesyonel
                  ekibini büyüterek çalışmalarına hız kesmeden devam etmektedir.
                </p>
              </div>
              <div className="history-context-right">
                <p className="history-title">2002</p>
                <img width={400} src={white} alt="" />
                <p className="history-text">
                  Kristal Peyzaj, 2002 yılında kurulan yaşam alanlarının
                  ihtiyaçlarını karşılamak üzere sunduğu hizmet kalitesini her
                  geçen gün geliştiriyor.
                </p>
              </div>
            </div>
            <div className="history-frame-context">
              <div className="history-context-right">
                <p className="history-title">2006</p>
                <img width={400} src={white} alt="" />
                <p className="history-text">
                  Altın Yapım 2006 'da fiilen kurulmuş olup, tanıtım filmi, web
                  tasarımı, kurumsal kimlik tasarımı, sosyal medya pazarlaması
                  hizmetleri adı altında hizmet vermektedir.
                </p>
              </div>
            </div>
            <div className="history-frame-context">
              <div className="history-context-left">
                <p className="history-title">2008</p>
                <img width={400} src={white} alt="" />
                <p className="history-text">
                  PAL FM, Pal Station Yayın hayatına başladığı 2008 yılından bu
                  yana Türkçe pop müzik sevenlere hizmet vermeye devam
                  etmektedir.
                </p>
              </div>
              <div className="history-context-right">
                <p className="history-title">2012</p>
                <img width={400} src={white} alt="" />
                <p className="history-text">
                  Up Medya, 2012 yılında kurulan matbaa ve promosyon
                  departmanları müşterilerine
                </p>
              </div>
            </div>
            <div className="history-frame-context">
              <div className="history-context-left">
                <p className="history-title">2014</p>
                <img width={400} src={white} alt="" />
                <p className="history-text">
                  Vizyon Havacılık, 2014 yılında bir Çam Grup Şirketi olarak
                  kurulmuş olup, Sivil Havacılık alanında faaliyetlerini
                  sürdürmektedir.
                </p>
              </div>
              <div className="history-context-right">
                <p className="history-title">2015</p>
                <img width={400} src={white} alt="" />
                <p className="history-text">
                  PİMA Elektronik Güvenlik Sistemleri konusunda hizmet vermek
                  amacıyla 2015 yılında Ankara’da kurulmuştur.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="cam-anime">
          <div className="history-line">
            <div className="dat">
              <img
                className="dat-image"
                width={10}
                height={10}
                src={dat}
                alt=""
              />
              <p className="com-text">Faaliyet Alanlarımız</p>
            </div>
          </div>
          <div className="cam-anime-title"></div>
          <div className="cam-anime-frame">
            <div className="cam-anime-frame-left">
              <p className="cam-anime-11">11</p>
              <p className="cam-anime-exp">FARKLI SEKTÖRDE FAALİYET</p>
            </div>
            <Link to="/Sektor">
              <div className="cam-anime-frame-right">
                <img
                  className="index-anime"
                  width={600}
                  height={600}
                  src={indexAnime3}
                  alt=""
                />
              </div>
            </Link>
          </div>
        </div>
        <div className="sektor">
          <div className="dat">
            <img
              className="dat-com-image"
              width={10}
              height={10}
              src={dat}
              alt=""
            />
            <p className="sektor-com-text">Sektörlerimiz</p>
            <img
              className="dat-com-image"
              width={10}
              height={10}
              src={dat}
              alt=""
            />
            <p className="sektor-com-text">Sektörlerimiz</p>
          </div>

          <img
            className="com-line"
            height={1870}
            width={2}
            src={lineD}
            alt=""
          />
          <div class="sektor-vektor">
            <div class="sektor-container">
              <div className="sektor1">
                <div className="sivil">
                  <img className="sektor-images" src={sivil} alt="" />
                  <div className="sivil-text responsive">
                    <p className="sektor-title">Sivil Havacılık</p>
                    <img className="sektor-white" src={white} alt="" />
                    <p className="sektor-text">
                      Havacılık endüstrisinde pilotaj eğitimlerinde “zero to
                      hero” şeklinde ifade edilen “sıfırdan ticari pilot
                      eğitimi, hava taksi, hangarlama işletmeciliği, AOC
                      hizmetleri ve SHY-145 bakım hizmetleri gibi katma değeri
                      yüksek operasyonları gerçekleştirmekteyiz.
                      <br />
                      Ankara Esenboğa Genel Havacılık terminalinde bulunan
                      hangarımız ve Nevşehir Kapadokya Havalimanı’nda bulunan
                      uçuş eğitim merkezimiz ile genel havacılığa dair her
                      alanda hizmetinizdeyiz!
                    </p>
                    <div class="sektor-btn2">
                      <Link to="/Sektor?type=sivil">
                        <p color="black">İncele</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="peysaj">
                  <div className="peysaj-text ">
                    <p className="sektor-title">Peyzaj</p>
                    <img className="sektor-white" src={white} alt="" />
                    <p className="sektor-text">
                      Doğadan esinlenerek, tabiatın gücünü kent hayatına
                      uyarlayan bir anlayışla 2002 yılında peyzaj sektörüne
                      girdiğimizden bu yana yaşam alanlarının ihtiyaçlarını
                      karşılamak üzere sunduğumuz hizmet kalitesini her geçen
                      gün geliştirmekteyiz.
                    </p>
                    <div class="sektor-btn">
                      <Link to="/Sektor?type=peysaj">
                        <p color="black">İncele</p>
                      </Link>
                    </div>
                  </div>
                  <img className="sektor-images" src={peysaj} alt="" />
                </div>
              </div>
            </div>
            <div class="sektor-container">
              <div className="sektor1">
                <div className="sivil">
                  <img className="sektor-images" src={fuar} alt="" />
                  <div className="fuar-text">
                    <p className="sektor-title">Fuar Organizasyon</p>
                    <img className="sektor-white" src={white} alt="" />
                    <p className="sektor-text">
                      Kuruluşundan bu yana ulusal ve uluslararası kongre, açılış
                      ve çeşitli organizasyonlara imza atarak,ve hedeflerimizi
                      büyüterek 2005 yılından itibaren fuar takvimini
                      genişletmiş ve başarılı kadromuz ile Türkiye’nin en büyük
                      ve en başarılı fuarlarını düzenlemekteyiz.
                    </p>
                    <div class="sektor-btn2">
                      {" "}
                      <Link to="/Sektor?type=fuar">
                        <p color="black">İncele</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="peysaj">
                  <div className="radyo-text">
                    <p className="sektor-title">Radyo</p>
                    <img className="sektor-white" src={white} alt="" />
                    <p className="sektor-text">
                      30 yıldır edindiğimiz sektör tecrübemiz ile bugün
                      geldiğimiz noktanın getirdiği teknolojik gereksinimleri
                      harmanlamamız sonucunda oluşan başarımızı;
                      dinleyicilerimizin takdirinde görmenin haklı gururunu
                      yaşamaktayız.
                      <br />
                      Ulusal bantta en çok dinlenen radyoların bir numarası
                      haline gelen ve jenerikleri ile dinleyicilerin akıllarına
                      kazınan markalarımız; PAL FM, PAL Station, PAL Nostalji,
                      PAL Doğa ile müziğin her halini dinleyicilerimiz ile
                      buluşturuyoruz.
                    </p>
                    <div class="sektor-btn">
                      <Link to="/Radio">
                        <p color="black">İncele</p>
                      </Link>
                    </div>
                  </div>
                  <img className="sektor-images" src={radyo} alt="" />
                </div>
              </div>
            </div>
            <div class="sektor-container">
              <div className="sektor1">
                <div className="sivil">
                  <img className="sektor-images" src={ticaret} alt="" />
                  <div className="dis-text">
                    <p className="sektor-title">Dış Ticaret</p>
                    <img className="sektor-white" src={white} alt="" />
                    <p className="sektor-text">
                      Dış ticaret alanında faaliyet geliştiren firmalarımız ile
                      Türkiye'nin ithalat ve ihracatının gelişimine katkı
                      sağlayarak, tecrübesi ve güvenilirliği ile sektöre değer
                      ve çeşitlilik kazandırdık.
                      <br />
                      Profesyonel ve doğru yaklaşım ile firmalarımız
                      uluslararası şirketler için distribütörlük yapan saygın
                      üyeler haline gelmiştir.
                    </p>
                    <div class="sektor-btn2">
                      <Link to="/Sektor?type=dis" rel="noopener noreferrer">
                        <p color="black">İncele</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/sektor">
              <div className="sektor-detay">DETAYLARI İNCELE</div>
            </Link>
          </div>
        </div>

        <div className="sirket-con">
          <div className="dat">
            <img
              className="dat-image"
              width={10}
              height={10}
              src={dat}
              alt=""
            />
            <p className="com-text">Şirketlerimiz</p>
          </div>
          <img className="com-line" height={700} width={2} src={lineD} alt="" />
          <div className="sirket-box">
            <div className="sirket-img">
              <img className="sirket-img-png" src={sirket} alt="" />
            </div>
            <div className="sirket-texts">
              <p className="sirket-title">Çam Grup Bünyesinde Şirketlerimiz</p>
              <img className="sirket-line" src={sirket_line} alt="" />
              <div className="sirket-exp">
                <p>
                  1996 yılından bu yana ilk günkü heyecanımız ile ilerlediğimiz
                  ticari yolculuğumuzda binlerce çalışana istihdam ve onlarca
                  ülke ile dış ticaret gerçekleştirerek ülke ekonomisine katma
                  değer sağladık.
                  <br />
                  30 yılın ardından edindiğimiz tecrübe ile yatırımlarımızı her
                  geçen gün büyüterek ilerliyoruz.
                </p>

                <p>
                  <br />
                  Faaliyet alanlarımız başlıca; dış ticaret, fuarcılık, peyzaj,
                  inşaat, turizm, medya, araç kiralama, havacılık, güvenlik,
                  bilişim ve teknoloji. Çam Grup olarak her zaman ileriye, daha
                  öteye gitmeye olan özverimiz ile bulunduğumuz her sektörü
                  ileriye götürmeye ve iş hacmini büyütmeye odaklıyız!
                </p>
                <br />
              </div>
              <a href="#top">
                <Link to="/Sektor">
                  <div className="sirket-btn">Şirketleri İncele</div>
                </Link>
              </a>
            </div>
          </div>
        </div>

        <div className="referans">
          <div className="order">
            <div className="slide">
              <img
                width={30}
                height={30}
                className="order-logos"
                src={prestij}
                alt=""
              />
            </div>
            <div className="slide">
              <img className="order-logos" src={camgroup} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={kristal} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={picclub} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={vafa} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={bsb} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos vizyon" src={vizyon} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={prestij} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={camgroup} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={kristal} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={picclub} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={vafa} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={bsb} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={vizyon} alt="" />
            </div>
          </div>
          <div className="order1">
            <div className="slide">
              <img className="order-logos" src={upmedya} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={imaj} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={info} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={altin} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={camturizm} alt="" />
            </div>
            
            <div className="slide">
              <img className="order-logos" src={upmedya} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={imaj} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={info} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={altin} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={camturizm} alt="" />
            </div>
            
          </div>
          <div className="order2">
            <div className="slide">
              <img className="order-logos" src={garanti} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={pima} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={pierre} alt="" />
            </div>
            
            <div className="slide">
              <img className="order-logos" src={palmedia} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={palfm} alt="" />
            </div>
            <div className="slide">
              <img
                width={155}
                height={25}
                className="order-logos"
                src={palnostalji}
                alt=""
              />
            </div>
            <div className="slide">
              <img
                width={25}
                height={25}
                className="order-logos"
                src={palstation}
                alt=""
              />
            </div>
            <div className="slide">
              <img className="order-logos" src={garanti} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={pima} alt="" />
            </div>
            
            <div className="slide">
              <img className="order-logos" src={palmedia} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={palfm} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={palnostalji} alt="" />
            </div>
            <div className="slide">
              <img className="order-logos" src={palstation} alt="" />
            </div>
          </div>
        </div>
        {/* <div className="radio">
          <div className="dat">
            <img
              className="dat-image"
              width={10}
              height={10}
              src={dat}
              alt=""
            />
            <p className="com-text">Radyolarımız</p>
          </div>
          <img className="com-line" height={400} width={2} src={lineD} alt="" />
          <div className="radio-box">
            <div className="radio-imgaes">
              <img className="radio-images-r" src={palfmR} alt="" />
              <img className="radio-images-png" src={radio} alt="" />
            </div>
            <div className="radio-text">
              <p>
                En çok dinlenen radyoların bir numarası haline gelen
                markalarımız;
                <br />
                PAL FM, PAL Station, PAL Nostalji, PAL Doğa ile müziğin her
                halini dinleyicilerimiz ile buluşturuyoruz.
              </p>
              <br />
              <p>
                24 saat kesintisiz yayın ve en tarafsız haber yayıncılığıyla
                Radyo sektörünün amiral gemilerinden birisidir. Kısa bir süre
                içerisinde birçok büyük markanın tercih ettiği Pal Fm, Türkçe
                pop müzik kategorisinde sayısız ödülün sahibi olmuştur ve geniş
                kitlelere ulaşmayı başarmıştır. Drive time özel showları, hafta
                sonu Popüler Djlerin canlı performansları ve Pop müziğin en ünlü
                sanatçıların konuk olduğu özel programlarıyla Pal Fm, 106.6.
                İstanbul ve 87.7 Ankara Frekanslarında kesintisiz canlı yayın
                yapmaktadır.
              </p>
            </div>
          </div>
        </div>
        <div className="radio-inspect">
          <Link to="/Radio" hrefLang="#top">
            Radyoları incele
          </Link>
        </div> */}
        <div className="communication">
          <div className="dat">
            <img
              className="dat-image"
              width={10}
              height={10}
              src={dat}
              alt=""
            />
            <p className="com-text">İletişim</p>
          </div>
          <img className="com-line" height={325} width={2} src={lineD} alt="" />
          <div className="com-box">
            <div className="com-item">
              <img className="logos" src={mail} alt="" />
              <a className="a-links" href="mailto:info@camgrup.com">
                info@camgrup.com
              </a>
            </div>
            <div className="com-item">
              <img className="logos" src={location} alt="" />
              <p>
                Ankara / Merkez <br /> Anıttepe Mahallesi Gazi Mustafa Kemal
                Bulvarı No:137 06570 Çankaya/ANKARA
              </p>
            </div>
            <div className="com-item">
              <img className="logos" src={phone} alt="" />
              <a className="a-links" href="tel:0 312 231 12 26">
                0 312 231 12 26
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
