import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import contactImage from "../assets/images/contact/contactImage.jpg";
import target from "../assets/images/contact/hedef.png";
import phoneImage from "../assets/images/contact/phoneImage.png";

function Contact() {
  return (
    <div className="con-contact">
      <Header />
      <div className="con-contact-page">
        <div className="services-ek">
          <div className="services-left">
            <img className="con-contactImage" src={contactImage} alt="" />
          </div>
          <div className="services-right">
            <div className="services-right-items">
              <div class="services-right-item">
                <div class="services-right-item-top">
                  <img className="services-target" src={target} alt="" />
                  <p className="services-right-item-top-title">ÇAM GRUP</p>
                </div>
                <div class="services-right-item-bottom">
                  <img alt="" />
                  <p className="services-right-item-bottom-exp">
                    Anıttepe Mahallesi Gazi Mustafa Kemal Bulvarı No:137 06570
                    Çankaya/ANKARA
                  </p>
                  <div className="services-phone">
                    <img
                      className="services-phone-img"
                      src={phoneImage}
                      alt=""
                    />
                    <a
                      href="tel:03122311226"
                      className="services-phone-exp a-links"
                    >
                      0 312 231 12 26
                    </a>
                  </div>
                </div>
              </div>
              <div class="services-right-item">
                <div class="services-right-item-top">
                  <img className="services-target" src={target} alt="" />
                  <p className="services-right-item-top-title">
                    İSTANBUL MEDİA CENTER
                  </p>
                </div>
                <div class="services-right-item-bottom">
                  <img alt="" />
                  <p className="services-right-item-bottom-exp">
                    Esenşehir Mahallesi Füsun Sokak No:34 34776 Ümraniye
                    İstanbul / TÜRKİYE
                  </p>
                  <div className="services-phone">
                    <img
                      className="services-phone-img"
                      src={phoneImage}
                      alt=""
                    />
                    <a
                      href="tel:02169093745"
                      className="services-phone-exp a-links"
                    >
                      0 216 909 37 45
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
