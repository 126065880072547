import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Slider from "react-slick";

import "./Gallery.css";

// ? slider styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// ? image imports
import square from "../../assets/images/gallery/squaregallery.png";
import line from "../../assets/images/gallery/galleryline.png";
import camgroup1 from "../../assets/images/gallery/camgrupgallery1.png";
import camgroup2 from "../../assets/images/gallery/camgrupgallery2.png";
import camgroup3 from "../../assets/images/gallery/camgrupgallery3.png";
import camgroup4 from "../../assets/images/gallery/camgrupgallery4.png";
import camgroup5 from "../../assets/images/gallery/camgrupgallery5.png";
import camgroup6 from "../../assets/images/gallery/camgrupgallery6.png";
import camgroup7 from "../../assets/images/gallery/camgrupgallery7.png";
import camgroup8 from "../../assets/images/gallery/camgrupgallery8.png";
import camagro1 from "../../assets/images/gallery/camagrogallery1.png";
import camagro2 from "../../assets/images/gallery/camagrogallery2.png";
import camagro3 from "../../assets/images/gallery/camagrogallery3.png";
import camagro4 from "../../assets/images/gallery/camagrogallery4.png";
import camagro5 from "../../assets/images/gallery/camagrogallery5.png";
import camagro6 from "../../assets/images/gallery/camagrogallery6.png";
import camagro7 from "../../assets/images/gallery/camagrogallery7.png";
import camvizyon1 from "../../assets/images/gallery/camvizyongallery1.png";
import camvizyon2 from "../../assets/images/gallery/camvizyongallery2.png";
import camvizyon3 from "../../assets/images/gallery/camvizyongallery3.png";
import camvizyon4 from "../../assets/images/gallery/camvizyongallery4.png";
import camvizyon5 from "../../assets/images/gallery/camvizyongallery5.png";
import camvizyon6 from "../../assets/images/gallery/camvizyongallery6.png";
import camvizyon7 from "../../assets/images/gallery/camvizyongallery7.png";
import camvizyon8 from "../../assets/images/gallery/camvizyongallery8.png";

export default function Gallery() {
  // * slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    slidesToShow: 2,

  };

  return (
    <>
      <Header />
      <div className="con-gallery">
        <div className="cam-web-gallery-main">
          <div className="cam-web-gallery-head">
            <img
              className="cam-web-gallery-first-line"
              src={line}
              alt="çizgi"
            />
            <p className="cam-web-gallery-p">GÖRSELLER</p>
            <img
              className="cam-web-gallery-second-line"
              src={line}
              alt="çizgi"
            />
            <div className="cam-web-gallery-head-right">
              <p className="cam-web-gallery-p2">ÇAM GRUP</p>
              <img className="cam-web-gallery-square" src={square} alt="kare" />
              <img className="cam-web-gallery-square" src={square} alt="kare" />
              <img className="cam-web-gallery-square" src={square} alt="kare" />
            </div>
          </div>
          <Slider {...settings}>
            <img src={camgroup1} alt="cam grup resimleri" />
            <img src={camgroup2} alt="cam grup resimleri" />
            <img src={camgroup3} alt="cam grup resimleri" />
            <img src={camgroup4} alt="cam grup resimleri" />
            <img src={camgroup5} alt="cam grup resimleri" />
            <img src={camgroup6} alt="cam grup resimleri" />
            <img src={camgroup7} alt="cam grup resimleri" />
            <img src={camgroup8} alt="cam grup resimleri" />
          </Slider>
          <div className="cam-web-gallery-head">
            <img
              className="cam-web-gallery-first-line"
              src={line}
              alt="çizgi"
            />
            <p className="cam-web-gallery-p">GÖRSELLER</p>
            <img
              className="cam-web-gallery-second-line"
              src={line}
              alt="çizgi"
            />
            <div className="cam-web-gallery-head-right">
              <p className="cam-web-gallery-p2">
                Agrotec 25. Uluslararası Tarım Fuarı/2022
              </p>
              <img className="cam-web-gallery-square" src={square} alt="kare" />
              <img className="cam-web-gallery-square" src={square} alt="kare" />
              <img className="cam-web-gallery-square" src={square} alt="kare" />
            </div>
          </div>
          <Slider {...settings}>
            <img src={camagro1} alt="Agrotec tarım fuarı resimleri" />
            <img src={camagro2} alt="Agrotec tarım fuarı resimleri" />
            <img src={camagro3} alt="Agrotec tarım fuarı resimleri" />
            <img src={camagro4} alt="Agrotec tarım fuarı resimleri" />
            <img src={camagro5} alt="Agrotec tarım fuarı resimleri" />
            <img src={camagro6} alt="Agrotec tarım fuarı resimleri" />
            <img src={camagro7} alt="Agrotec tarım fuarı resimleri" />
          </Slider>
          <div className="cam-web-gallery-head">
            <img
              className="cam-web-gallery-first-line"
              src={line}
              alt="çizgi"
            />
            <p className="cam-web-gallery-p">GÖRSELLER</p>
            <img
              className="cam-web-gallery-second-line"
              src={line}
              alt="çizgi"
            />
            <div className="cam-web-gallery-head-right">
              <p className="cam-web-gallery-p2">Vizyon havacılık hangar</p>
              <img className="cam-web-gallery-square" src={square} alt="kare" />
              <img className="cam-web-gallery-square" src={square} alt="kare" />
              <img className="cam-web-gallery-square" src={square} alt="kare" />
            </div>
          </div>
          <Slider {...settings}>
            <img src={camvizyon1} alt="Vizyon Havacılık resimleri" />
            <img src={camvizyon2} alt="Vizyon Havacılık resimleri" />
            <img src={camvizyon3} alt="Vizyon Havacılık resimleri" />
            <img src={camvizyon4} alt="Vizyon Havacılık resimleri" />
            <img src={camvizyon5} alt="Vizyon Havacılık resimleri" />
            <img src={camvizyon6} alt="Vizyon Havacılık resimleri" />
            <img src={camvizyon7} alt="Vizyon Havacılık resimleri" />
            <img src={camvizyon8} alt="Vizyon Havacılık resimleri" />
          </Slider>
        </div>
      </div>
      <Footer />
    </>
  );
}
