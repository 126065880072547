import React from "react";
import "../App.css";
import camLogo from "../assets/cam-logo.png";
import arrow from "../assets/lea-arrow.png";
import { Link } from "react-router-dom";
import { IconName } from "react-icons/fa";

export default function Header() {
  return (
    <>
      <header className="top-menu">
        <div className="header-links">
          <Link className="cam-logo" to="/">
            <img className="cam-logo-png" src={camLogo} alt="cam logo" />
          </Link>
          <div className="links">
            <div className="link">
              <Link className="header-link" to="/About">
                Hakkımızda
              </Link>
            </div>
            {/* <div className="link">
              <Link className="header-link" to="/plus">
                Faaliyet Alanlarımız
              </Link>
            </div> */}
            <div className="link">
              <Link className="header-link" to="/Sektor">
                Sektörlerimiz
              </Link>
            </div>
            <div className="link">
              <Link className="header-link" to="/Gallery">
                Fotoğraf Galerisi
              </Link>
            </div>
            <div className="link">
              <Link className="header-link" to="/Contact">
                İletişim
              </Link>
            </div>
          </div>
          {/* <div className="dil">
            <p>TR</p>
            <img className="lea-arrow" src={arrow} alt="" />
          </div> */}
        </div>
      </header>
    </>
  );
}
