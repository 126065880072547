import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Service() {
  return (
    <div className="con-service">
      <Header />
      <p>ServiceYT</p>
      <Footer />
    </div>
  );
}

export default Service;
