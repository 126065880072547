import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function About() {
  return (
    <div className="con-about">
      <Header />
      <div className="about-us-con">
        <div className="about-us-con-top">
          <div className="about-us-con-top-1">
            <p className="about-us-con-title">
              <span className="about-us-con-title-dat">.</span>Vizyonumuz;
              <span className="about-us-con-title-dat">.</span>
            </p>
            <p className="about-us-con-exp">
              Kalitede, hizmette, kaynakları geliştirmede, insan ilişkilerine
              verdiğimiz önemde daima “en iyi” olmak vazgeçilmez hedefimizdir.
            </p>
          </div>
          <div className="about-us-con-top-2">
            <p className="about-us-con-title">
              {" "}
              <span className="about-us-con-title-dat">.</span>Misyonumuz;{" "}
              <span className="about-us-con-title-dat">.</span>
            </p>
            <p className="about-us-con-exp">
              İlerlediği yolda dürüstlüğünden, çalışma disiplininden, insan
              kaynaklarına ve gelişimine verdiği önemden taviz vermeden,
              yaratıcı çözümler ve sürdürülebilir değerler oluşturmak için en
              yetkili, en tecrübeli çalışanlarıyla güvenilir, rekabetçi,
              mükemmel ve verimli değerler ortaya çıkarmaktır.
            </p>
          </div>
        </div>
        <div className="about-us-con-bottom">
          <p className="about-us-con-title">
            <span className="about-us-con-title-dat">.</span>
            Yönetim Kurulu Başkanı’nın Mesajı
            <span className="about-us-con-title-dat">.</span>
          </p>
          <p className="about-us-con-exp">
            <p>Değerli iş ortaklarımız ve sevgili çalışma arkadaşlarımız,</p>{" "}
            <br />{" "}
            <p>
              Çam Grup olarak sahip olduğumuz 30 yıllık geçmişimiz sürecinde
              siyasi, ekonomik, kültürel ve teknolojik bir çok dönüşüme tanıklık
              ettik. Gelişen teknoloji, bununla beraber hayatımıza yeni giren
              kavramlar karşısında her daim çevik kararlar aldık.
            </p>
            <br />{" "}
            <p>
              İnanıyorum ki; yeni çağın gereklerine ayak uydururken dikkat
              etmemiz gereken noktalardan belki de en kıymetlisi mükerrer
              yatırımlar yapmak yerine özel sektör girişimcisinin eksik
              noktalarını tespit etmek ve bu bağlamda tamamlayıcı yatırımlara
              yönelmek, ayrıca bunları gerçekleştirirken de en son teknolojiyi
              kullanmaktır.
            </p>
            <br />
            <p>
              {" "}
              Çıktığımız bu ticaret yolculuğunda büyük bir kararlılıkla
              ilerlerken edindiğimiz bütün tecrübe ve birikimlerimizi yeni
              nesillerimize aktarmayı, ülkemizin aydınlık geleceğini inşa etmek
              adına üzerimize düşen bir sorumluluk olarak görüyorum.
            </p>
            <br />{" "}
            <p>
              Çam Grup Şirketleri ailesi olarak, parlak bir geleceğe doğru
              güvenle yol alacağımızı memnuniyetle temenni ediyorum.
            </p>
          </p>
          <p className="about-us-con-title">
            <p className="about-name-style">Ali Okan ÇAM</p>

            <p className="yonetim">
              <span className="about-us-con-title-dat">.</span> Yönetim Kurulu
              Başkanı <span className="about-us-con-title-dat">.</span>
            </p>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
