import React, { useEffect } from "react";
import Home from "./pages/Home";
import { Route, Routes, Link, NavLink, useLocation } from "react-router-dom";
import Sektor from "./pages/Sektor.js";
import Plus from "./pages/Plus.js";
import "./App.css";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Gallery from "./pages/gallery/Gallery";
import Service from "./pages/Service";
import Radio from "./pages/Radyolar";
import Peysaj from "./pages/Peysaj";

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (typeof windows !== undefined) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Plus" element={<Plus />} />
      <Route path="/Sektor" element={<Sektor />} />
      <Route path="/Gallery" element={<Gallery />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Service" element={<Service />} />
      <Route path="/Radio" element={<Radio />} />
      <Route path="/Peysaj" element={<Peysaj />} />
    </Routes>
  );
}
