import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../App.css";

function Plus() {
  return (
    
      <div className="con-plus">
        <Header />

        <p>Faaliyet Alanlarımız</p>
        <Footer />
      </div>
   
  );
}

export default Plus;
