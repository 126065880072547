import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

// ? css styles
import "./css/Radyolar.css";
import "../App.css";

// ? Logos
import palmedialogo from "../assets/images/radyolar/palmedialogo.png";
import palfmlogo from "../assets/images/radyolar/palfmlogo.png";
import palstationlogo from "../assets/images/radyolar/palstationlogo.png";
import palnostlogo from "../assets/images/radyolar/palnostlogo.png";
import paldogalogo from "../assets/images/radyolar/paldogalogo.png";
import palorilogo from "../assets/images/radyolar/palorilogo.png";
import palmnalogo from "../assets/images/radyolar/palmnalogo.png";
import hedeflogo from "../assets/images/radyolar/hedeflogo.png";
import radyogruplogo from "../assets/images/radyolar/radyogruplogo.png";

// ? İmages
import palfmimage from "../assets/images/radyolar/palfmimage.jpg";
import palstationimage from "../assets/images/radyolar/palstationimage.png";
import palnostimage from "../assets/images/radyolar/palnostimage.png";
import paldogaimage from "../assets/images/radyolar/paldogaimage.png";
import paloriimage from "../assets/images/radyolar/paloriimage.png";
import palmnaimage from "../assets/images/radyolar/palmnaimage.jpg";
import hedefimage from "../assets/images/radyolar/hedefimage.jpg";
import radyogrupimage from "../assets/images/radyolar/radyogrupimage.png";

export default function Radyolar() {
  return (
    <div className="con-cam-web-radyo">
      <Header />

      <div className="con-cam-web-radyo-main">
        <div className="cam-web-radyo-header">
          <h1>RADYOLAR</h1>
          <div className="cam-web-radyo-logo">
            <img src={palmedialogo} alt="Pal media logo" />
          </div>
        </div>
        <div className="cam-web-radyo-palfm">
          <img src={palfmimage} alt="kulaklık ve mikrofon" />
          <div className="cam-web-radyo-palfm-exp">
            <p>
              25 yıldır edindiğimiz sektör tecrübemiz ile bugün geldiğimiz
              noktanın getirdiği teknolojik gereksinimleri harmanlamamız
              sonucunda oluşan başarımızı; dinleyicilerimizin takdirinde
              görmenin haklı gururunu yaşamaktayız. <br /> <br />
              Ulusal bantta en çok dinlenen radyoların bir numarası haline gelen
              ve jenerikleri ile dinleyicilerin akıllarına kazınan markalarımız;
              <br /> <br />
              <strong>PAL FM, PAL Station, PAL Nostalji, PAL Doğa </strong>ile
              müziğin her halini dinleyicilerimiz ile buluşturuyoruz.
            </p>
            <div className="cam-web-radyo-palfm-inf">
              <div className="cam-web-radyo-palfm-inf-fre">
                <img src={palfmlogo} alt="palfm logo" />
                <p>
                  İstanbul &nbsp; &nbsp; <strong>99,0</strong> <br />
                  Ankara &nbsp; &nbsp; &nbsp; <strong>87,7</strong>{" "}
                </p>
              </div>
              <hr />
              <p>
                Uydu Bilgileri : AzerSpace-1 <br />
                Frekans : 10998 <br />
                Sembol Rate : 3300
              </p>{" "}
              <br />
            </div>
          </div>
        </div>
        <div className="cam-web-radyo-palsta">
          <div className="cam-web-radyo-palsta-outline">
            <div className="cam-web-radyo-palsta-inf">
              <div className="cam-web-radyo-palsta-inf-head">
                <div className="cam-web-radyo-palsta-inf-head-logo">
                  <img src={palstationlogo} alt="palstation logo" />
                </div>
                <a target="_blank" href="http://www.palstation106.com/">
                  www.palstation106.com
                </a>
              </div>
              <div className="cam-web-radyo-palsta-inf-city">
                <div className="cam-web-radyo-palsta-inf-city-head">
                  <p>Şehir</p>
                </div>
                <p>İstanbul</p>
                <p>Ankara</p>
                <p>Balıkesir</p>
                <p>Çanakkale</p>
                <p>Tekirdağı</p>
                <p>Sakarya</p>
                <p>İzmir</p>
              </div>
              <div className="cam-web-radyo-palsta-inf-fre">
                <div className="cam-web-radyo-palsta-inf-fre-head">
                  <p>Frekans</p>
                </div>
                <p>106,0</p>
                <p>99,3</p>
                <p>106,0</p>
                <p>106,0</p>
                <p>106,0</p>
                <p>104,8</p>
                <p>99,3</p>
              </div>
            </div>
            <div className="cam-web-radyo-palsta-inf-exp">
              <p>
                <strong>Pal Station</strong> Yabancı müzik yayını yapan ulusal
                radyolar kategorisindeki başarısının yanısıra genel
                değerlendirmelerde de gittikçe başarısını arttıran radyoların
                başında geliyor. 2008 den bu yana ‘Sadece Hit Müzik’ sloganıyla
                geçmişten günümüze sadece hit olmuş şarkılara gün boyu kulak
                verebilirsiniz.
              </p>
            </div>
          </div>
          <img src={palstationimage} alt="dj ekipmanı kullanan birisi" />
        </div>
        <div className="cam-web-radyo-palnost">
          <img src={palnostimage} alt="radyolar" />
          <div className="cam-web-radyo-palnost-outline">
            <div className="cam-web-radyo-palsta-inf">
              <div className="cam-web-radyo-palsta-inf-head">
                <div className="cam-web-radyo-palsta-inf-head-logo">
                  <img src={palnostlogo} alt="palstation logo" />
                </div>
                <a target="_blank" href="https://www.palnostalji.com.tr/">
                  www.palnostalji.com
                </a>
              </div>
              <div className="cam-web-radyo-palsta-inf-city">
                <div className="cam-web-radyo-palsta-inf-city-head">
                  <p>Şehir</p>
                </div>
                <p>İstanbul</p>
                <p>Ankara</p>
                <p>İzmir</p>
                <p>bursa</p>
                <p>Antalya</p>
                <p>Konya</p>
                <p>Ankara</p>
              </div>
              <div className="cam-web-radyo-palsta-inf-fre">
                <div className="cam-web-radyo-palnost-inf-city-head">
                  <p>Frekans</p>
                </div>
                <p>99,2</p>
                <p>91,4</p>
                <p>107,3</p>
                <p>106,0</p>
                <p>99,1</p>
                <p>99,3</p>
                <p>87,3</p>
              </div>
            </div>
            <div className="cam-web-radyo-palsta-inf-exp">
              <p>
                Türkiye'nin ilk ulusal nostalji radyosu{" "}
                <strong>Pal Nostalji'de</strong> usta DJ'ler 70'li, 80'li ve
                90'lı yılların unutulmayan şarkılarını sizler için çalıyor! Pal
                Nostalji, tüm Türkiye'de yayında!
              </p>
            </div>
          </div>
        </div>
        <div className="cam-web-radyo-paldog">
          <div className="cam-web-radyo-paldog-inf">
            <div className="cam-web-radyo-paldog-inf-head">
              <div className="cam-web-radyo-paldog-inf-head-logo">
                <img src={paldogalogo} alt="paldoga logo" />
              </div>
              <a target="_blank" href="http://www.paldoga.com.tr/">
                www.paldoga.com
              </a>
            </div>
            <div className="cam-web-radyo-paldog-inf-main">
              <div className="cam-web-radyo-paldog-inf-city">
                <div className="cam-web-radyo-paldog-inf-city-head">
                  <p>Şehir</p>
                </div>
                <p>İstanbul</p>
                <p>Ankara</p>
              </div>
              <div className="cam-web-radyo-paldog-inf-city">
                <div className="cam-web-radyo-paldog-inf-fre-head">
                  <p>Frekans</p>
                </div>
                <p>104,8</p>
                <p>100,9</p>
              </div>
            </div>
          </div>
          <div className="cam-web-radyo-paldog-exp">
            <div className="cam-web-radyo-paldog-exp-p">
              <p>
                1996 yılında yayın hayatına başlayan
                <strong> Pal DOĞA </strong>100.9 frekansında Ankara’da 24 saat
                kesintisiz müzik yayını sunuyor. Kültür - sanat faaliyetleri
                yürüten kuruluşların tanıtımıyla halk kültürünün
                yaygınlaşmasında açık bir köprü görevi gören Pal Doğa Radyo
                doğru ve düzgün Türkçe ile sorumlu yayıncılık anlayışını
                benimsemiş bir yayın kuruluşudur.
              </p>
            </div>
            <div className="cam-web-radyo-paldog-exp-fre">
              <p>
                <strong>Uydu Bilgileri :</strong> <br />
                AzerSpace-1 Frekans : 10998 <br />
                Sembol Rate : 3300 <br />
                Fec : H-Yatay{" "}
              </p>
            </div>
          </div>
          <img src={paldogaimage} alt="saz" />
        </div>
        <div className="cam-web-radyo-palori">
          <img src={paloriimage} alt="saz" />
          <div className="cam-web-radyo-palori-inf">
            <div className="cam-web-radyo-palori-inf-head">
              <div className="cam-web-radyo-palori-inf-head-logo">
                <img src={palorilogo} alt="paldoga logo" />
              </div>
              <a target="_blank" href="http://palorient.com.tr/index.html">
                www.palorient.com.tr
              </a>
            </div>
          </div>
          <div className="cam-web-radyo-palori-exp">
            <div
              style={{ height: "100%" }}
              className="cam-web-radyo-paldog-exp-p"
            >
              <p>
                {" "}
                <strong>PAL Orient</strong> frekanslarında, Arapça, Hintçe,
                Farsça ve İbranice gibi farklı dünya dillerinin yanı sıra Balkan
                ülkelerinde geçmişten günümüze en sevilen hitlerini
                dinleyicileriyle buluşturuyor.
              </p>
            </div>
          </div>
        </div>
        <div className="cam-web-radyo-hedef">
          <div className="cam-web-radyo-hedef-inf">
            <div className="cam-web-radyo-hedef-inf-head">
              <div className="cam-web-radyo-hedef-inf-head-logo">
                <img src={hedeflogo} alt="paldoga logo" />
              </div>
              <a target="_blank" href="http://www.hedefradyo.com/">
                www.hedefradyo.com
              </a>
            </div>
          </div>
          <div className="cam-web-radyo-hedef-exp">
            <div className="cam-web-radyo-hedef-exp-p">
              <p>
                1992’de Hedef Basın Yayın Filmcilik Sanayi ve Ticaret Anonim
                Şirketi olarak Ankara da kurulmuş olup, 1993‘de Hedef Radyo
                logosuyla radyomuz yayın hayatına başlamıştır.
              </p>
            </div>
          </div>
          <img src={hedefimage} alt="saz" />
        </div>
        <div className="cam-web-radyo-grup">
          <img src={radyogrupimage} alt="saz" />
          <div className="cam-web-radyo-grup-inf">
            <div className="cam-web-radyo-grup-inf-head">
              <div className="cam-web-radyo-hedef-inf-head-logo">
                <img src={radyogruplogo} alt="paldoga logo" />
              </div>
              <a target="_blank" href="http://www.radyogrup.com/">
                www.radyogrup.com
              </a>
            </div>
          </div>
          <div className="cam-web-radyo-grup-exp">
            <div className="cam-web-radyo-hedef-exp-p">
              <p>
                Ulusal bantta en çok dinlenen radyoların bir numarası haline
                gelen ve jenerikleri ile dinleyicilerin akıllarına kazınan
                markalarımız; <br /> <br />
                <strong>
                  {" "}
                  PAL FM, PAL Station, PAL Nostalji, PAL Doğa
                </strong>{" "}
                ile müziğin her halini dinleyicilerimiz ile buluşturuyoruz.
              </p>
            </div>
          </div>
        </div>
        <div className="cam-web-radyo-palmna">
          <div className="cam-web-radyo-palmna-outline">
            <div className="cam-web-radyo-palmna-inf">
              <div className="cam-web-radyo-palmna-inf-head">
                <div className="cam-web-radyo-palmna-inf-head-logo">
                  <img src={palmnalogo} alt="palstation logo" />
                </div>
                <a target="_blank" href="http://palminafm.com.tr/">
                  www.palmnafm.com
                </a>
              </div>
            </div>
          </div>
          <img src={palmnaimage} alt="dj ekipmanı kullanan birisi" />
        </div>
      </div>

      <Footer />
    </div>
  );
}
